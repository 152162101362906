import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import SplashNavbar from "../components/Navbars/SplashNavbar.js";
import Footer from "../components/Footer/Footer.js";

import routes from "../routes.js";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardText,
} from "reactstrap";
import livingroom from "assets/img/bg/squarespace-livingroom.jpg";

var ps;

const MultipleLoans = (props) => {
  const TemplateConfig = useSelector((state) => state.TemplateConfig);
  const [configRoutes, setConfigRoutes] = useState(routes);
  const location = useLocation();
  const [sidebarMini, setSidebarMini] = React.useState(false);
  const mainPanel = React.useRef();
  // const [loans] = React.useState(props?.loanFile);
  const [loans] = React.useState(() => {
    const initialLoans = props?.loanFile;
    return Array.isArray(initialLoans) ? initialLoans : initialLoans ? [initialLoans] : [];
  });
  
  const { logout } = useAuth0();

  React.useEffect(() => {
    if (navigator?.platform?.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator?.platform?.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routesParam = null) => {
    let myRoutes = routesParam ?? configRoutes;
    let matched = myRoutes.map((x) => x.layout + x.path);
    return myRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/multiple/loans") {
        if (props?.user?.app_metadata?.isAdverse) {
          return <Redirect to="" />;
        }
        if (matched.includes(location.pathname)) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={() => prop.component}
              key={key}
            />
          );
        } else {
          console.log("REDIRECT 404");
          return <Redirect to="" />;
        }
      } else {
        return null;
      }
    });
  };

  const getName = () => {
    let borrowerName = 'Guest';
    const firstBorrower = loans?.[0]?.borrowers?.[0];

    if (firstBorrower) {
        const { firstName, middleName, lastName, suffixName } = firstBorrower;
        borrowerName = `${firstName || ''} ${middleName || ''} ${lastName || ''} ${suffixName || ''}`
            .trim()
            .replace(/\s+/g, ' ');
    }

    return borrowerName;
};

  useEffect(() => {
    setConfigRoutes(routes);
  }, [TemplateConfig]);

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  if (props.loanFileErr) {
    return (
      <div className="wrapper">
        <div ref={mainPanel}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              verticalAlign: "center",
              marginTop: "50px",
            }}
          >
            <Card
              style={{
                width: "25rem",
              }}
            >
              <img alt="Sample" src="https://picsum.photos/300/200" />
              <CardBody>
                <CardTitle tag="h5">Uh-Oh!</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Internal Server Error
                </CardSubtitle>
                <CardText>
                  We were unable to access your loan information. Please try
                  logging out and logging back in, or try logging in from a
                  different web browser (Edge, Chrome or Firefox). If the issue
                  persists please contact Loan Depot.
                </CardText>
                <Button
                  className="button purple_style"
                  onClick={() => {
                    localStorage.removeItem("selectedLoan");
                    localStorage.removeItem('hasVisitedSplashPage');
                    return logout({
                      returnTo: `${window.location.origin}${process.env.REACT_APP_HOMEPAGE_PATH}`,
                    });
                  }}
                >
                  Return to Borrower Portal Login
                </Button>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  const onHandleLoanSelect = async (loan) => {
    localStorage.setItem("selectedLoan", JSON.stringify(loan));
    window.location.reload();
  };

  return (
    <div className="wrapper">
      <div ref={mainPanel}>
        {props.user ? (
          <>
            <SplashNavbar
              {...props}
              handleMiniClick={handleMiniClick}
              multipleLoans={true}
            />
            <Switch>{getRoutes()}</Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              props?.location?.pathname?.indexOf("full-screen-map") !==
              -1 ? null : (
                <Footer fluid />
              )
            }
            <img
              src={livingroom}
              width="100%"
              height="200px"
              style={{ objectFit: "cover", objectPosition: "0 55%" }}
            />
            <div
              style={{
                position: "relative",
                bottom: "100px",
                zIndex: "100",
                textAlign: "center",
                color: "white",
              }}
            >
              {props.user ? (
                <h2
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0",
                    textTransform: "capitalize",
                  }}
                >
                  Hi {getName()}!
                </h2>
              ) : (
                <h2 style={{ fontWeight: "bold", marginBottom: "0" }}>...</h2>
              )}

              <p style={{ fontWeight: "bold" }}>Welcome to your loan portal.</p>
            </div>

            <div className="container-fluid w-100 text-center">
              <p className="multiple-loans-text">
                We found more than one active loan application in your lending
                portfolio.
              </p>
            </div>

            <div className="container-fluid w-100 text-center footer-multiple-loans">
              <p className="m-0 mt-5">Please select a loan file to continue</p>
            </div>
            <div className="container-fluid w-100 multiple-loans-container">
              {loans?.map((loan) => {
                return (
                  <div
                    key={loan?.loanNumber}
                    className="loan-element"
                    onClick={(event) => {
                      event.preventDefault();
                      onHandleLoanSelect(loan);
                    }}
                  >
                    <p className="loan-number">Loan #{loan.loanNumber}</p>
                    <p className="m-0">
                      {loan.property?.streetAddress}{" "}
                      {loan.property?.state}{" "}
                      {loan.property?.postalCode}
                    </p>
                    <span className="loan-status">
                      {loan?.status === "In-Progress" ? "Loan" : "Loan in"}{" "}
                      {loan?.status}
                    </span>
                    <p className="loan-create-date">
                      Applied for {loan?.applicationDate}
                    </p>

                    <div className="loan-arrow-container">
                      <i class="fa-regular fa-circle-right"></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MultipleLoans;
